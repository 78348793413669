import { motion, useMotionValue, useTransform } from "framer-motion";
import Club from "./Club";
import Counter from "./Counter";

function App() {
  const cardX = useMotionValue(0);
  const cardY = useMotionValue(0);
  const rotateX = useTransform(cardY, [-600, 600], [50, -50]); // Reversed values
  const rotateY = useTransform(cardX, [-600, 600], [-50, 50]); // Reversed values
  const cardRotateX = useTransform(cardY, [-600, 600], [50, -50]); // Adjusted rotation values
  const cardRotateY = useTransform(cardX, [-600, 600], [-50, 50]); // Adjusted rotation values

  const handleMouseMove = (event) => {
    const offsetX = event.clientX - window.innerWidth / 2;
    const offsetY = event.clientY - window.innerHeight / 2;

    cardX.set(offsetX);
    cardY.set(offsetY);
  };

  const handleMouseLeave = () => {
    cardX.set(0);
    cardY.set(0);
  };

  return (
    <motion.div
      style={{
        perspective: 800,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      className="relative flex font-black overflow-hidden font-mono text-slate-100 items-center justify-center h-[100vh] w-[100vw] bg-slate-950"
    >
      <motion.div
        style={{
          width: "100vw",
          height: "100vh",
          transformStyle: "preserve-3d",
          perspective: 800,
          display: "flex",
          justifyContent: "center",
          transition: "0.1s ease transform",
          alignItems: "center",
          rotateX,
          rotateY,
        }}
        transition={{ velocity: 0 }}
      >
        <motion.div
          key="card"
          className="flex justify-center items-center"
          style={{
            borderRadius: 10,
            width: "100vw",
            height: "100vh",
            minWidth: "100vw",
            transformStyle: "preserve-3d",
            perspective: 800,
            cardRotateX,
            cardRotateY,
          }}
          transition={{ velocity: 0 }}
        >
          <Club />

          <div
            style={{
              transform: "ranslateZ(4rem)",
            }}
          >
            <Counter from={0} to={48} />
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default App;
