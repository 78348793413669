import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

function Club() {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const size = animate(0, 40, {
      duration: 1,
      delay: 0.5,
      ease: "backInOut",
      onUpdate(value) {
        node.style.fontSize = value + "vw";
      },
    });

    return () => {
      size.stop();
    };
  });

  return (
    <div
      ref={nodeRef}
      className="absolute text-transparent bg-clip-text bg-gradient-to-b from-slate-950 via-purple-900/30 via-80% to-slate-950 overflow-hidden left-0 right-0 flex align-baseline justify-center text-[40vw]"
    >
      КЛУБ
    </div>
  );
}

export default Club;
