import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

function Counter({ from, to }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 2.5,
      ease: "circInOut",
      onUpdate(value) {
        node.textContent = value.toFixed(0);
      },
    });

    const opacity = animate(0, 1, {
      duration: 3,
      ease: "anticipate",
      onUpdate(value) {
        node.style.opacity = value;
      },
    });

    const size = animate(0, 30, {
      duration: 2.5,
      bounce: 1,
      damping: 3,
      ease: "anticipate",
      onUpdate(value) {
        node.style.fontSize = value + "vw";
      },
    });

    return () => {
      controls.stop();
      opacity.stop();
      size.stop();
    };
  }, [from, to]);

  return (
    <p
      className="text-transparent from-slate-900  via-slate-500 to-slate-300 bg-gradient-to-b bg-clip-text drop-shadow-2xl"
      ref={nodeRef}
    />
  );
}

export default Counter;
